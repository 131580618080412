import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faCommentDots,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import IdentificationForm from "./identificationForm";
import ValuationForm from "./valuationForm";
import ServiceSchedulingForm from "./repairForm";
import { useStore } from "../store";

import PartFetchingComponent from "./partFetchingComponent";

import { Helmet } from "react-helmet";

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative w-[25vw]">
      <img
        src={images[currentImageIndex]}
        alt={`Bike ${currentImageIndex + 1}`}
        className="w-full h-auto object-cover rounded-lg"
      />
      {images.length > 1 && (
        <>
          <button
            onClick={prevImage}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={nextImage}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {images.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentImageIndex ? "bg-white" : "bg-gray-400"
                }`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const InsuranceMode = ({ selectedModel, valuation, userData, buttonStyle }) => {
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Bike Valuation</h2>
      <p className="mb-4">{valuation.details.description}</p>
      <ul className="mb-4 list-disc pl-5">
        <li>MSRP: {valuation.details.msrp}</li>
        <li>Private Sale Value: {valuation.details.private_sale_value}</li>
        <li>Trade-in Value: {valuation.details.trade_in_value}</li>
        <li>Replacement Cost: {valuation.details.replacement_cost}</li>
      </ul>
      <button
        className="font-bold py-2 px-4 rounded mt-4"
        style={buttonStyle}
        onClick={() => {
          window.location.href = "insurance-quotes.html";
        }}
      >
        Get Insurance Quotes
      </button>
    </div>
  );
};

const MarketplaceListingMode = ({
  selectedModel,
  valuation,
  location,
  userData,
  buttonStyle,
  bikeData,
}) => {
  const [listingTitle, setListingTitle] = useState(
    valuation.details.marketplace_listing_title
  );
  const [listingDescription, setListingDescription] = useState(
    valuation.details.marketplace_listing_description
  );
  const [listingPrice, setListingPrice] = useState(
    valuation.details.private_sale_value
  );

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Create Marketplace Listing</h2>
      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full md:w-1/2 px-2 mb-4">
          <img
            src={bikeData.original_image_url}
            alt={selectedModel.model}
            className="w-full h-auto rounded-lg"
          />
        </div>
        <div className="w-full md:w-1/2 px-2">
          <div className="mb-4">
            <label className="block mb-2">Listing Title:</label>
            <input
              type="text"
              value={listingTitle}
              onChange={(e) => setListingTitle(e.target.value)}
              className="border p-2 w-full rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Location:</label>
            <p className="border p-2 w-full rounded bg-gray-100">
              {location ? location.label : "Not specified"}
            </p>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Price:</label>
            <input
              type="text"
              value={listingPrice}
              onChange={(e) => setListingPrice(e.target.value)}
              className="border p-2 w-full rounded"
            />
          </div>
          <div className="mb-4 p-4 bg-gray-100 rounded">
            <h3 className="font-bold mb-2">Valuation Metrics:</h3>
            <ul className="list-disc pl-5">
              <li>MSRP: {valuation.details.msrp}</li>
              <li>
                Private Sale Value: {valuation.details.private_sale_value}
              </li>
              <li>Trade-in Value: {valuation.details.trade_in_value}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full mb-4">
        <label className="block mb-2">Description:</label>
        <textarea
          value={listingDescription}
          onChange={(e) => setListingDescription(e.target.value)}
          className="border p-2 w-full rounded"
          rows="6"
        ></textarea>
      </div>
      <button className="font-bold py-2 px-4 rounded mt-4" style={buttonStyle}>
        Create Listing
      </button>
    </div>
  );
};
const RepairSchedulingMode = ({ selectedModel, userData }) => {
  const [issueDescription, setIssueDescription] = useState("");
  const [preferredDate, setPreferredDate] = useState("");

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Schedule Repair</h2>
      <div className="mb-4">
        <label className="block mb-2">Describe the issue:</label>
        <textarea
          value={issueDescription}
          onChange={(e) => setIssueDescription(e.target.value)}
          className="border p-2 w-full rounded"
          rows="4"
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Preferred service date:</label>
        <input
          type="date"
          value={preferredDate}
          onChange={(e) => setPreferredDate(e.target.value)}
          className="border p-2 w-full rounded"
        />
      </div>
      <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
        Schedule Repair
      </button>
    </div>
  );
};

const PartCompatibilityMode = ({ selectedModel, bikeData, userData }) => {
  const [parts, setParts] = useState([]);
  const [selectedPartType, setSelectedPartType] = useState("");
  const [compatibleParts, setCompatibleParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const getPartsList = async () => {
    setIsLoading(true);
    setProgress(0);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/identification/parts`,
        { bike_model: selectedModel },
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
        }
      );

      const { job_id } = response.data;

      let jobComplete = false;
      while (!jobComplete) {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const statusResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/sheldon/identification/parts/jobs/${job_id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (statusResponse.status === 202) {
          setProgress(statusResponse.data.progress);
        }
        if (statusResponse.status === 200) {
          jobComplete = true;
          setParts(statusResponse.data.parts);
        }
      }
    } catch (error) {
      console.error("Error fetching parts list:", error);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  useEffect(() => {
    if (selectedModel) {
      getPartsList();
    }
  }, [selectedModel, getPartsList]);

  const checkCompatibility = async () => {
    setIsLoading(true);
    setProgress(0);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/compatibility`,
        {
          bike_model: selectedModel,
          bike_parts: parts,
          part_type: selectedPartType,
        },
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
        }
      );

      const { job_id } = response.data;

      let jobComplete = false;
      while (!jobComplete) {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const statusResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/sheldon/compatibility/jobs/${job_id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (statusResponse.status === 202) {
          setProgress(statusResponse.data.progress);
        }
        if (statusResponse.status === 200) {
          jobComplete = true;
          setCompatibleParts(statusResponse.data.compatible_parts);
        }
      }
    } catch (error) {
      console.error("Error checking compatibility:", error);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  if (isLoading) {
    return (
      <div className="p-4">
        <p className="mb-2">
          {parts.length === 0
            ? "Fetching parts list..."
            : "Checking compatibility..."}
        </p>
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="mt-2">{progress}% complete</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Check Part Compatibility</h2>
      <div className="mb-4">
        <label className="block mb-2">Select part type:</label>
        <select
          value={selectedPartType}
          onChange={(e) => setSelectedPartType(e.target.value)}
          className="border p-2 w-full rounded"
        >
          <option value="">Select a part type</option>
          {parts.map((part, index) => (
            <option key={index} value={part.type}>
              {part.type}
            </option>
          ))}
        </select>
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={checkCompatibility}
        disabled={!selectedPartType}
      >
        Check Compatibility
      </button>
      {compatibleParts.length > 0 && (
        <div className="mt-4">
          <h3 className="text-xl font-bold mb-2">Compatible Parts:</h3>
          <ul className="list-disc pl-5">
            {compatibleParts.map((part, index) => (
              <li key={index}>{part}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const ValuationMode = ({
  selectedModel,
  valuation,
  userData,
  valuationData,
  buttonStyle,
}) => {
  const getFormDataValue = (key) => userData.get(key);
  return (
    <div className="h-full p-4 overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4">Bike Valuation</h2>
      <div className="flex flex-wrap -mx-2 mb-4">
        <div className="w-full md:w-1/2 px-2 mb-4">
          <img
            src={URL.createObjectURL(getFormDataValue("file"))}
            alt={selectedModel.model}
            className="w-full h-auto rounded-lg"
          />
        </div>
        <div className="w-full md:w-1/2 px-2">
          <div className="mb-4 p-4 bg-gray-100 rounded">
            <h3 className="font-bold mb-2">Bike Details:</h3>
            <ul className="list-disc pl-5">
              <li>Year: {selectedModel.year}</li>
              <li>Make: {selectedModel.manufacturer}</li>
              <li>Model: {selectedModel.model}</li>
              {selectedModel.model_version && (
                <li>Version: {selectedModel.model_version}</li>
              )}
              <li>Color: {selectedModel.color_option}</li>
              {selectedModel.selectedSize && (
                <li>Size: {selectedModel.selectedSize}</li>
              )}
            </ul>
          </div>
          <div className="mb-4 p-4 bg-gray-100 rounded">
            <h3 className="font-bold mb-2">Valuation Factors:</h3>
            <ul className="list-disc pl-5">
              <li>Condition: {valuationData.condition}</li>
              <li>Estimated Miles: {valuationData.estimatedMiles}</li>
              <li>Modifications: {valuationData.modifications || "None"}</li>
              <li>Location: {valuationData.location.label}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mb-4 p-4 bg-bikewise-green text-white rounded">
        <h3 className="font-bold mb-2">Valuation Metrics:</h3>
        <ul className="list-disc pl-5">
          <li>MSRP: {valuation.details.msrp}</li>
          <li>Private Sale Value: {valuation.details.private_sale_value}</li>
          <li>Trade-in Value: {valuation.details.trade_in_value}</li>
          <li>Replacement Cost: {valuation.details.replacement_cost}</li>
        </ul>
      </div>
      {valuation.details.description && (
        <div className="mb-4">
          <h3 className="font-bold mb-2">Valuation Description:</h3>
          <p>{valuation.details.description}</p>
        </div>
      )}
    </div>
  );
};

const BikeWiseWidget = ({ selectedMode }) => {
  // CSS styles
  const [settings, setSettings] = useState({
    backgroundColor: "#f0f0f0",
    textColor: "#333333",
    primaryColor: "#47888a",
    secondaryColor: "#204f58",
    buttonTextColor: "#ffffff",
    type: "insurance",
  });

  const [demoContent, setDemoContent] = useState(null);

  // State
  const [selectedModel, setSelectedModel] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bikeModels, setBikeModels] = useState([]);
  const [bikeData, setBikeData] = useState(null);
  const [valuation, setValuation] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [condition, setCondition] = useState("");
  const [estimatedMiles, setEstimatedMiles] = useState("");
  const [modifications, setModifications] = useState("");
  const [location, setLocation] = useState(null);
  const [serialNumber, setSerialNumber] = useState("");
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [elapsedTime, setElapsedTime] = useState(0);
  const [widgetMode, setWidgetMode] = useState("insurance");
  const [partsList, setPartsList] = useState(null);
  const [isLoadingParts, setIsLoadingParts] = useState(false);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [userData, setUserData] = useState(null);
  const [valuationData, setValuationData] = useState(null);

  // Chat state
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const chatContainerRef = useRef(null);

  const userId = useStore((state) => state.userInfo?.id);

  const [partialBikeData, setPartialBikeData] = useState(null);

  const pollJobStatus = async (
    jobId,
    apiEndpoint,
    updateProgress,
    initialInterval = 1000,
    maxInterval = 120000,
    maxDuration = 300000
  ) => {
    const startTime = Date.now();
    let interval = initialInterval;
    let totalElapsedTime = 0;

    while (totalElapsedTime < maxDuration) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      totalElapsedTime = Date.now() - startTime;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${apiEndpoint}/${jobId}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (response.status === 200) {
          setIsLoading(false);
          return response.data;
        } else if (response.status === 202) {
          updateProgress(
            response.data.progress,
            response.data.data.status,
            totalElapsedTime
          );

          // Check for partial results and update the UI
          if (response.data.data.initialBikeDetails) {
            const partialData = response.data.data;
            setPartialBikeData(partialData);
          }
        }
      } catch (error) {
        console.error("Error polling job status:", error);
        throw error;
      }

      // Implement exponential backoff after 45 seconds
      if (totalElapsedTime > 45000) {
        interval = Math.min(interval * 2, maxInterval);
      }
    }

    throw new Error("Job timed out");
  };

  // Styles
  const buttonStyle = {
    backgroundColor: settings.primaryColor,
    color: settings.buttonTextColor,
    opacity:
      !selectedModel ||
      (widgetMode !== "repair" && (!condition || !estimatedMiles))
        ? 0.5
        : 1,
    cursor:
      !selectedModel ||
      (widgetMode !== "repair" && (!condition || !estimatedMiles))
        ? "not-allowed"
        : "pointer",
  };

  // Effects
  useEffect(() => {
    if (selectedMode) {
      setWidgetMode(selectedMode);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const modeParam = urlParams.get("mode");
      if (
        modeParam &&
        [
          "insurance",
          "marketplace",
          "repair",
          "compatibility",
          "valuation",
        ].includes(modeParam)
      ) {
        setWidgetMode(modeParam);
      }
    }
  }, [selectedMode]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Image upload
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => handleImageSubmission(acceptedFiles[0]),
  });

  const handleImageSubmission = async (file) => {
    try {
      setIsLoading(true);
      setProgress(0);
      setError(null);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("get_product_images", "false");
      formData.append("user_id", userId); // Add user email to the form data

      await processImage(formData);
    } catch (error) {
      console.error("Error in handleImageSubmission:", error);
      setIsLoading(false);
      setError(
        `Uh oh, we're having trouble uploading your bike image. Please try again. Error: ${error.message}`
      );
    }
  };

  const processImage = async (formData) => {
    setUserData(formData);
    setIsLoading(true);
    setProgress(0);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/identification`,
        formData,
        {
          headers: {
            "Content-Type":
              formData instanceof FormData
                ? "multipart/form-data"
                : "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );

      const { job_id, thread_id } = response.data;
      setThreadId(thread_id);

      const result = await pollJobStatus(
        job_id,
        "/sheldon/identification",
        (progress, status, time) => {
          setProgress(progress);
          setStatus(status);
          setElapsedTime(time);
        }
      );

      setIsLoading(false);
      if (result.bike_models && result.bike_models.length === 1) {
        setSelectedModel(result.bike_models[0]);
      }
      setBikeData(result);
      setBikeModels(result.bike_models);
      setProgress(0);
    } catch (error) {
      setIsLoading(false);
      setProgress(0);
      if (error.message === "Job timed out") {
        setError(
          "The bike identification process is taking longer than expected. Please try again later."
        );
      } else {
        setError(
          "An error occurred while processing the image. Please try again."
        );
      }
      console.error("Error:", error);
    }
  };

  const getValuation = async (model, threadId, valuationData) => {
    setIsLoading(true);
    setProgress(0);
    setLocation(valuationData.location);

    setValuationData(valuationData);

    try {
      const initiateResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/valuation`,
        {
          selected_model: model,
          thread_id: threadId,
          ...valuationData,
          location: valuationData.location,
          country_code: valuationData.country_code,
          size: model.selectedSize,
        },
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
        }
      );

      const { job_id } = initiateResponse.data;

      const result = await pollJobStatus(
        job_id,
        "/sheldon/valuation",
        (progress, status, time) => {
          setProgress(progress);
          setStatus(status);
          setElapsedTime(time);
        }
      );

      console.log(result);

      setIsLoading(false);
      setProgress(0);
      setValuation(result);
    } catch (error) {
      setIsLoading(false);
      setProgress(0);
      if (error.message === "Job timed out") {
        setError(
          "The valuation process is taking longer than expected. Please try again later."
        );
      } else {
        setError(
          "An error occurred while getting the valuation. Please try again."
        );
      }
      console.error("Error:", error);
    }
  };

  const handleSmartFormComplete = (formData) => {
    handleBikeIdentification(formData);
  };

  const handleBikeIdentification = async (data) => {
    setUserData(data);
    setIsLoading(true);
    setProgress(0);
    setError(null);

    try {
      const requestData = {
        ...data,
        user_id: userId, // Add user email to the request data
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/identification`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
          },
        }
      );

      const { job_id, thread_id } = response.data;
      setThreadId(thread_id);

      const result = await pollJobStatus(
        job_id,
        "/sheldon/identification",
        (progress, status, time) => {
          setProgress(progress);
          setStatus(status);
          setElapsedTime(time);
        }
      );

      setIsLoading(false);
      if (result.bike_models && result.bike_models.length === 1) {
        setSelectedModel(result.bike_models[0]);
      }
      setBikeData(result);
      setBikeModels(result.bike_models);
      setProgress(0);
    } catch (error) {
      setIsLoading(false);
      setProgress(0);
      if (error.message === "Job timed out") {
        setError(
          "The bike identification process is taking longer than expected. Please try again later."
        );
      } else {
        setError(
          "An error occurred while processing the information. Please try again."
        );
      }
      console.error("Error:", error);
    }
  };

  // Components
  const ChatButton = () => (
    <button
      className="absolute top-2 right-2 bg-bikewise-green text-white rounded-full px-4 py-2 shadow-lg hover:bg-bikewise-secondary transition-colors duration-200"
      onClick={() => setIsChatOpen(!isChatOpen)}
      aria-label={isChatOpen ? "Close Chat" : "Open Chat"}
    >
      <FontAwesomeIcon icon={faCommentDots} className={`text-xl`} />{" "}
      {isChatOpen ? "Close Chat" : "Chat With Sheldon"}
    </button>
  );

  const ChatInterface = ({ messages, threadId, setMessages }) => {
    const chatContainerRef = useRef(null);
    const textareaRef = useRef(null);
    const [localInputMessage, setLocalInputMessage] = useState("");
    const [isMessageLoading, setIsMessageLoading] = useState(false);

    const predefinedOptions = [
      "I'm not sure which version is mine",
      "I don't think any of these are my bike",
      "I have a different year",
    ];

    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, [messages]);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [localInputMessage]);

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        sendMessage(localInputMessage);
      }
    };

    const handleInputChange = (e) => {
      setLocalInputMessage(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;
    };

    const sendMessage = async (message) => {
      if (message.trim() === "") return;

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: message, sender: "user" },
      ]);
      setLocalInputMessage("");
      setIsMessageLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sheldon`,
          {
            message: `In the context of our current conversation please: ${message}`,
            existingThreadId: threadId,
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        const assistantMessage = {
          text: response.data.message.message,
          sender: "assistant",
        };

        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      } catch (error) {
        console.error("Error sending message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            text: "Sorry, there was an error processing your request. Please try again.",
            sender: "assistant",
          },
        ]);
      } finally {
        setIsMessageLoading(false);
      }
    };

    return (
      <div className="flex flex-col h-full w-full bg-white rounded-lg shadow-xl">
        <div
          ref={chatContainerRef}
          className="flex-grow overflow-y-auto p-4 space-y-2"
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.sender === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`max-w-3/4 p-2 rounded-lg ${
                  message.sender === "assistant"
                    ? "bg-bikewise-green text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {message.text}
              </div>
            </div>
          ))}
          {isMessageLoading && (
            <div className="flex justify-start">
              <div className="max-w-3/4 p-2 rounded-lg bg-bikewise-green text-white">
                <div className="flex items-center">
                  <div className="w-2 h-2 bg-white rounded-full mr-1 animate-bounce"></div>
                  <div
                    className="w-2 h-2 bg-white rounded-full mr-1 animate-bounce"
                    style={{ animationDelay: "0.2s" }}
                  ></div>
                  <div
                    className="w-2 h-2 bg-white rounded-full animate-bounce"
                    style={{ animationDelay: "0.4s" }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-2 border-t">
          {bikeModels && bikeModels.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-2">
              {predefinedOptions.map((option, index) => (
                <button
                  key={index}
                  onClick={() => sendMessage(option)}
                  className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-1 px-2 rounded text-sm"
                >
                  {option}
                </button>
              ))}
            </div>
          )}
          <div className="flex items-end">
            <textarea
              ref={textareaRef}
              value={localInputMessage}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              className="flex-grow border rounded-l-lg p-2 resize-none"
              placeholder="Type your message..."
              rows={1}
              style={{
                minHeight: "40px",
                maxHeight: "120px",
                overflowY: "auto",
              }}
            />
            <button
              onClick={() => sendMessage(localInputMessage)}
              className="bg-bikewise-green text-white rounded-r-lg px-4 py-2 h-10 flex items-center justify-center"
              disabled={isMessageLoading}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const idealBikeImageInfo = (
    <div className="flex flex-col md:flex-row gap-4 mt-4 p-4 bg-gray-100 rounded-lg">
      <div className="md:w-1/2">
        <h3 className="text-lg font-semibold mb-2">
          Example of an ideal bike image:
        </h3>
        <img
          src="https://res.cloudinary.com/hjvufswct/image/upload/v1719949311/bike/scrldvdxx4viza7lbzkx.jpg"
          alt="Example of ideal bike"
          className="w-full rounded-lg shadow-md"
        />
      </div>
      <div className="md:w-1/2">
        <h3 className="text-lg font-semibold mb-2">
          Ideals for bike identification:
        </h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>Full side view of the bike</li>
          <li>Right side of the bike with drive train visible</li>
          <li>Solid or contrasting color background</li>
          <li>Horizontal orientation</li>
          <li>Clear, well-lit image</li>
          <li>No obstructions or overlays on the bike</li>
          <li>High resolution for detail visibility</li>
          <li>Entire bike in frame, including both wheels</li>
        </ul>
      </div>
    </div>
  );

  const renderBikeIdentification = () => (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">
        {widgetMode === "insurance"
          ? "Get Insurance Quote"
          : widgetMode === "marketplace"
          ? "Create Marketplace Listing"
          : widgetMode === "compatibility"
          ? "Check Part Compatibility"
          : widgetMode === "valuation"
          ? "Bike Valuation"
          : widgetMode === "demo"
          ? "Demo Mode"
          : widgetMode === "parts"
          ? "Parts Identification"
          : "Schedule Repair"}
      </h2>
      <div className="mb-4">
        <div className="flex items-center w-full gap-2">
          <div
            {...getRootProps()}
            className="flex-1 border-dashed border-2 border-gray-300 p-4 cursor-pointer rounded-lg"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the image here...</p>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <img src="/images/bikeOverlay.png" className="w-1/4" />
                <p>Drag and drop an image here, or click to select an image</p>
              </div>
            )}
          </div>
          <div className="relative">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="flex mt-2 text-sm text-gray-500 cursor-pointer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
            {isHovered && (
              <div
                className="fixed transform -translate-x-full -translate-y-1/2 rounded-lg shadow-lg z-50 w-1/2 max-w-[75vw]"
                style={{ marginLeft: "-5px" }} // Adjust this value to fine-tune the left position
              >
                {idealBikeImageInfo}
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <h3 className="text-xl font-bold mb-2">Or use our smart form:</h3>

          {/* 
          
          <form onSubmit={handleUrlSubmission} className="flex">
            <input
              type="url"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              onBlur={() => {
                if (imageUrl && !isValidImageUrl(imageUrl)) {
                  setError(
                    "Please enter a valid image URL. The URL should end with an image extension (e.g., .jpg, .png) or contain '/images/' in the path."
                  );
                }
              }}
              placeholder="Enter image URL"
              className={`border p-2 mr-2 flex-grow rounded ${
                imageUrl && !isValidImageUrl(imageUrl) ? "border-red-500" : ""
              }`}
              required
            />
            <button
              type="submit"
              style={{
                backgroundColor: settings.primaryColor,
                color: settings.buttonTextColor,
              }}
              className="font-bold py-2 px-4 rounded"
              disabled={!imageUrl || !isValidImageUrl(imageUrl)}
            >
              Submit URL
            </button>
          </form>
          
          */}
          <IdentificationForm
            onComplete={handleSmartFormComplete}
            settings={settings}
          />
        </div>
      </div>
    </div>
  );

  const fetchPartsList = async (model) => {
    setIsLoadingParts(true);
    setProgress(0);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sheldon/identification/parts`,
        { bike_model: model },
        {
          headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` },
        }
      );

      const { job_id } = response.data;

      let jobComplete = false;
      while (!jobComplete) {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const statusResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/sheldon/identification/parts/jobs/${job_id}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (statusResponse.status === 202) {
          setProgress(statusResponse.data.progress);
        }
        if (statusResponse.status === 200) {
          jobComplete = true;
          setPartsList(statusResponse.data.parts);
        }
      }
    } catch (error) {
      console.error("Error fetching parts list:", error);
      setError(
        "An error occurred while fetching the parts list. Please try again."
      );
    } finally {
      setIsLoadingParts(false);
      setProgress(0);
    }
  };

  const renderBikeSelector = () => {
    const updateModelField = (model, field, value) => {
      const updatedModel = { ...model, [field]: value };
      setSelectedModel(updatedModel);
      setBikeModels(bikeModels.map((m) => (m === model ? updatedModel : m)));
    };

    const handleBackToList = () => {
      setSelectedModel(null);
    };

    const renderHelpButton = () => (
      <button
        onClick={() => setIsChatOpen(true)}
        className="mt-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
      >
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        Need help identifying your model?
      </button>
    );

    return (
      <div
        className="p-2 rounded-lg overflow-y-auto"
        style={{ backgroundColor: settings.backgroundColor }}
      >
        <div id="model-list" className="mb-4">
          {selectedModel ? (
            <div>
              {bikeModels.length > 1 && (
                <button
                  onClick={handleBackToList}
                  className="mb-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
                  Back to list
                </button>
              )}
              <div className="bg-bikewise-green p-2 rounded-lg overflow-hidden shadow-md">
                <div className="flex flex-col h-full">
                  <div className="flex w-full items-center justify-center">
                    <img
                      src={bikeData.original_image_url}
                      alt="original_image_1"
                      className="w-1/3 h-auto rounded-lg"
                    />
                  </div>
                  <div className="p-4 overflow-y-auto">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="mb-1">
                        <label className="block text-sm font-medium text-white">
                          Year:
                        </label>
                        <input
                          type="number"
                          value={selectedModel.year}
                          onChange={(e) =>
                            updateModelField(
                              selectedModel,
                              "year",
                              e.target.value
                            )
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        />
                      </div>
                      <div className="mb-1">
                        <label className="block text-sm font-medium text-white">
                          Make:
                        </label>
                        <input
                          type="text"
                          value={selectedModel.manufacturer}
                          onChange={(e) =>
                            updateModelField(
                              selectedModel,
                              "manufacturer",
                              e.target.value
                            )
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        />
                      </div>
                      <div className="mb-1">
                        <label className="block text-sm font-medium text-white">
                          Model:
                        </label>
                        <input
                          type="text"
                          value={selectedModel.model}
                          onChange={(e) =>
                            updateModelField(
                              selectedModel,
                              "model",
                              e.target.value
                            )
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        />
                      </div>
                      <div className="mb-1">
                        {selectedModel.model_version && (
                          <>
                            <label className="block text-sm font-medium text-white">
                              Model Version:
                            </label>
                            <input
                              type="text"
                              value={selectedModel.model_version}
                              onChange={(e) =>
                                updateModelField(
                                  selectedModel,
                                  "model_version",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                            />
                          </>
                        )}
                      </div>
                      <div className="mb-1">
                        <label className="block text-sm font-medium text-white">
                          Color:
                        </label>
                        <input
                          type="text"
                          value={selectedModel.color_option}
                          onChange={(e) =>
                            updateModelField(
                              selectedModel,
                              "color_option",
                              e.target.value
                            )
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        />
                      </div>
                      <div className="mb-1">
                        <label
                          htmlFor="size"
                          className="block text-sm font-medium text-white"
                        >
                          Size:
                        </label>
                        <select
                          id="size"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                          onChange={(e) =>
                            updateModelField(
                              selectedModel,
                              "selectedSize",
                              e.target.value
                            )
                          }
                          value={selectedModel.selectedSize || ""}
                        >
                          <option value="">Select size</option>
                          {(selectedModel.size_options &&
                          Array.isArray(selectedModel.size_options) &&
                          selectedModel.size_options.length > 0
                            ? selectedModel.size_options
                            : [
                                "XS",
                                "S",
                                "M",
                                "L",
                                "XL",
                                "XXL",
                                "48cm",
                                "50cm",
                                "52cm",
                                "54cm",
                                "56cm",
                                "58cm",
                                "60cm",
                                "62cm",
                              ]
                          ).map((size, sizeIndex) => (
                            <option key={sizeIndex} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isChatOpen ? renderHelpButton() : null}
            </div>
          ) : bikeModels &&
            Array.isArray(bikeModels) &&
            bikeModels.length > 0 ? (
            <div className="flex flex-col justify-center items-center">
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                {bikeModels.map((model, index) => (
                  <div
                    key={index}
                    className="bg-bikewise-green text-white p-4 cursor-pointer rounded-lg overflow-hidden shadow-md transition-all duration-200 hover:shadow-xl hover:bg-bikewise-darkGreen"
                    onClick={() => setSelectedModel(model)}
                  >
                    <div className="flex flex-col h-full">
                      <div className="flex w-full items-center justify-center">
                        <img
                          src={bikeData.original_image_url}
                          alt="original_image_2"
                          className="w-3/4 h-auto rounded-lg"
                        />
                      </div>
                      <div className="p-4 flex items-center justify-center">
                        <h3 className="font-bold">
                          {model.year} {model.manufacturer} {model.model} <br />
                          {model.model_version}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-4">
                <span className="font-bold">Differences</span>
                {bikeData.bike_model_differences &&
                  bikeData.bike_model_differences.length > 0 &&
                  bikeData.bike_model_differences.map((difference, index) => (
                    <ul key={index} className="list-disc pl-5">
                      <li>{difference}</li>
                    </ul>
                  ))}
              </div>
              {!isChatOpen ? renderHelpButton() : null}
            </div>
          ) : (
            <div>
              <p>No bike models found.</p>
              {renderHelpButton()}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderPartialResults = () => {
    if (!partialBikeData) return null;

    const renderModelDetails = (model) => (
      <div className="mt-4 p-4 bg-bikewise-green rounded-lg text-white flex items-center justify-center font-bold">
        {model.year && (
          <div>
            {model.year} {model.manufacturer} {model.model}{" "}
            {model.model_version}
          </div>
        )}
      </div>
    );

    return (
      <div className="p-2 rounded-lg overflow-y-auto">
        <div id="partial-results" className="mb-4">
          <div className="flex flex-col gap-4 items-center justify center w-full">
            <div className="md:w-1/2">
              <img
                src={partialBikeData.image_url}
                alt="Uploaded Bike"
                className="w-full h-auto rounded-lg"
              />
            </div>
            <div className="md:w-1/2">
              {renderModelDetails(partialBikeData.initialBikeDetails)}
              {partialBikeData.initialBikeDetails.bike_model_differences &&
                partialBikeData.initialBikeDetails.bike_model_differences
                  .length > 0 && (
                  <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                    <h3 className="font-bold mb-2">
                      Other possible variations:
                    </h3>
                    <ul className="list-disc pl-5">
                      {partialBikeData.initialBikeDetails.bike_model_differences.map(
                        (difference, index) => (
                          <li key={index}>{difference}</li>
                        )
                      )}
                    </ul>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDemoAction = async (action) => {
    setIsLoading(true);
    try {
      let response;
      switch (action) {
        case "valuation":
          response = await getValuation(selectedModel, threadId, valuationData);
          setValuation(response);
          setDemoContent(
            <ValuationMode
              selectedModel={selectedModel}
              valuation={response}
              userData={userData}
              valuationData={valuationData}
              buttonStyle={buttonStyle}
            />
          );
          break;
        case "description":
          setDemoContent(
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="font-bold mb-2">Description:</h3>
              <p>{selectedModel.description || "No description available."}</p>
            </div>
          );
          break;
        case "highlights":
          setDemoContent(
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="font-bold mb-2">Highlights:</h3>
              <ul className="list-disc pl-5">
                {selectedModel.highlights?.map((highlight, index) => (
                  <li key={index}>
                    {highlight.label}: {highlight.value}
                  </li>
                )) || <li>No highlights available.</li>}
              </ul>
            </div>
          );
          break;
        case "reviews":
          setDemoContent(
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="font-bold mb-2">Reviews:</h3>
              {selectedModel.reviews?.map((review, index) => (
                <div key={index} className="mb-2">
                  <p>
                    <strong>Rating:</strong> {review.rating}/5
                  </p>
                  <p>{review.text}</p>
                </div>
              )) || <p>No reviews available.</p>}
            </div>
          );
          break;
        case "tags":
          setDemoContent(
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="font-bold mb-2">Tags:</h3>
              <div className="flex flex-wrap gap-2">
                {selectedModel.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-bikewise-green text-white px-2 py-1 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                )) || <p>No tags available.</p>}
              </div>
            </div>
          );
          break;
        case "rawJson":
          setDemoContent(
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="font-bold mb-2">Raw JSON:</h3>
              <pre className="overflow-x-auto">
                {JSON.stringify(selectedModel, null, 2)}
              </pre>
            </div>
          );
          break;
        default:
          setDemoContent(null);
      }
    } catch (error) {
      console.error("Error in demo action:", error);
      setError("An error occurred while performing the demo action.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderDemoButtons = () => (
    <div className="flex flex-wrap gap-2 mt-4">
      <button
        onClick={() => handleDemoAction("valuation")}
        className="bg-bikewise-green text-white font-bold py-2 px-4 rounded"
      >
        Get Valuation
      </button>
      <button
        onClick={() => handleDemoAction("description")}
        className="bg-bikewise-green text-white font-bold py-2 px-4 rounded"
      >
        See Description
      </button>
      <button
        onClick={() => handleDemoAction("highlights")}
        className="bg-bikewise-green text-white font-bold py-2 px-4 rounded"
      >
        See Highlights
      </button>
      <button
        onClick={() => handleDemoAction("reviews")}
        className="bg-bikewise-green text-white font-bold py-2 px-4 rounded"
      >
        See Reviews
      </button>
      <button
        onClick={() => handleDemoAction("tags")}
        className="bg-bikewise-green text-white font-bold py-2 px-4 rounded"
      >
        See Tags
      </button>
      <button
        onClick={() => handleDemoAction("rawJson")}
        className="bg-bikewise-green text-white font-bold py-2 px-4 rounded"
      >
        See Raw JSON
      </button>
    </div>
  );

  const renderWidgetContent = () => {
    if (isLoading) {
      return (
        <div className="p-4 w-full">
          {renderPartialResults()}
          <div className="mt-4">
            <p className="mb-2">{status || "Processing..."}</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-600">
              <div
                className="h-2.5 rounded-full"
                style={{
                  width: `${progress}%`,
                  backgroundColor: settings.primaryColor,
                }}
              ></div>
            </div>
            <p className="mt-2">
              {progress}% complete | {elapsedTime / 1000}s
            </p>
          </div>
        </div>
      );
    }

    if (bikeModels && bikeModels.length === 0) {
      return renderBikeIdentification();
    }

    if (bikeModels.length > 0) {
      return (
        <>
          {renderBikeSelector()}
          {selectedModel && (
            <>
              {!valuation &&
                widgetMode !== "repair" &&
                widgetMode !== "parts" && (
                  <ValuationForm
                    settings={settings}
                    initialData={{
                      condition,
                      estimatedMiles,
                      modifications,
                      serialNumber,
                    }}
                    renderButton={(isValid, formData) => (
                      <button
                        className="font-bold py-2 px-4 rounded mt-4 w-full"
                        style={{
                          ...buttonStyle,
                          opacity: isValid ? 1 : 0.5,
                          cursor: isValid ? "pointer" : "not-allowed",
                        }}
                        onClick={() => {
                          if (isValid) {
                            getValuation(selectedModel, threadId, formData);
                          }
                        }}
                        disabled={!isValid}
                      >
                        Get Valuation
                      </button>
                    )}
                  />
                )}
              {(valuation ||
                widgetMode === "repair" ||
                widgetMode === "parts") &&
                renderModeContent()}
            </>
          )}
        </>
      );
    }

    return null;
  };

  const renderModeContent = () => {
    switch (widgetMode) {
      case "insurance":
        return (
          <InsuranceMode
            selectedModel={selectedModel}
            valuation={valuation}
            userData={userData}
            buttonStyle={buttonStyle}
          />
        );
      case "marketplace":
        return (
          <MarketplaceListingMode
            userData={userData}
            bikeData={bikeData}
            location={location}
            selectedModel={selectedModel}
            valuation={valuation}
            buttonStyle={buttonStyle}
          />
        );
      case "repair":
        return (
          <ServiceSchedulingForm
            settings={settings}
            initialData={{
              bikeDetails: `${selectedModel.year} ${selectedModel.manufacturer} ${selectedModel.model} (Size: ${selectedModel.selectedSize})`,
            }}
            renderButton={(isValid, formData) => (
              <button
                className="font-bold py-2 px-4 rounded mt-4 w-full"
                style={{
                  ...buttonStyle,
                  opacity: isValid ? 1 : 0.5,
                  cursor: isValid ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (isValid) {
                    console.log("Repair scheduling data:", formData);
                    // Add your logic here to submit the repair scheduling data
                  }
                }}
                disabled={!isValid}
              >
                Schedule Repair
              </button>
            )}
          />
        );
      case "compatibility":
        return (
          <PartCompatibilityMode
            selectedModel={selectedModel}
            bikeData={bikeData}
            userData={userData}
            buttonStyle={buttonStyle}
          />
        );
      case "valuation":
        return (
          <ValuationMode
            selectedModel={selectedModel}
            valuation={valuation}
            userData={userData}
            valuationData={valuationData}
            buttonStyle={buttonStyle}
          />
        );
      case "parts":
        return (
          <PartFetchingComponent
            selectedModel={selectedModel}
            threadId={threadId}
            settings={settings}
          />
        );
      case "demo":
        return (
          <>
            {renderDemoButtons()}
            {demoContent}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full max-h-[85vh]">
      <Helmet>
        <script>{`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3784764,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}</script>
        <script>{`
          window.hjLoaded = function() {
            document.dispatchEvent(new Event('hotjarLoaded'));
          };
        `}</script>
      </Helmet>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="relative w-full h-full flex items-center justify-center max-w-4xl p-8 bg-white rounded-lg shadow-lg">
          {!isLoading ? <ChatButton /> : null}
          <div className="flex justify-center items-stretch h-full w-full">
            <div
              className={`transition-all duration-300 ease-in-out ${
                isChatOpen
                  ? "w-2/3"
                  : "w-full h-full flex items-center justify-center"
              } overflow-auto pr-4`}
            >
              {error && (
                <div>
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
                    role="alert"
                  >
                    <span className="block sm:inline">{error}</span>
                  </div>
                  {idealBikeImageInfo}
                </div>
              )}
              <div className="w-full h-full" id="bikewise-widget">
                {renderWidgetContent()}
              </div>
            </div>
            {isChatOpen && (
              <div className="w-1/2 pl-4 border-l">
                <ChatInterface
                  messages={messages}
                  threadId={threadId}
                  setMessages={setMessages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikeWiseWidget;
