import React, { useState, useEffect } from "react";
import axios from "axios";
import BikeWiseWidget from "../../widget-legacy/widgetInternal";

const ResponsiveBikeWiseLayout = () => {
  const [activeTab, setActiveTab] = useState("widget");
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState("valuation");

  const modes = ["demo", "valuation", "marketplace", "insurance", "parts"];

  const addToHistory = async (item) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/history`, { item });
    } catch (err) {
      console.error("Error adding to history:", err);
    }
  };

  const renderHistory = () => {
    if (isLoading) {
      return <p>Loading history...</p>;
    }

    if (error) {
      return <p className="text-red-500">{error}</p>;
    }

    if (history.length === 0) {
      return <p>No history items yet.</p>;
    }

    return (
      <ul className="space-y-2">
        {history.map((item, index) => (
          <li key={index} className="bg-white p-3 rounded shadow">
            {item.content}
            <span className="text-xs text-gray-500 block mt-1">
              {new Date(item.timestamp).toLocaleString()}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const ModeButton = ({ mode }) => (
    <button
      className={`py-2 px-4 text-sm font-medium rounded-tr-lg ${
        selectedMode === mode
          ? "bg-bikewise-red text-white"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      } transition-colors duration-200`}
      onClick={() => setSelectedMode(mode)}
    >
      {mode.charAt(0).toUpperCase() + mode.slice(1)}
    </button>
  );

  const renderModeButtons = () => (
    <div className="flex justify-between items-center">
      <div className="flex">
        {modes.map((mode) => (
          <ModeButton key={mode} mode={mode} />
        ))}
      </div>
    </div>
  );

  return (
    <div
      className="w-full h-full min-h-screen min-w-screen bg-cover bg-center"
      style={{ backgroundImage: `url("/images/backgroundStars.svg")` }}
    >
      <div className="hidden md:flex flex-col h-full">
        <div className="bg-gray-100">{renderModeButtons()}</div>
        <div className="p-8">
          <BikeWiseWidget
            selectedMode={selectedMode}
            addToHistory={addToHistory}
          />
        </div>
      </div>

      {/* Mobile layout */}
      <div className="flex flex-col h-screen md:hidden">
        <div className="bg-gray-100">{renderModeButtons()}</div>
        <div className="flex-grow overflow-auto">
          <BikeWiseWidget
            selectedMode={selectedMode}
            addToHistory={addToHistory}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsiveBikeWiseLayout;
